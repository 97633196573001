import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import {getFinishYears, notesOnly, withoutNotes} from "../utils/utils"

import PropTypes from "prop-types"

const FinishPicker = ({finishes, model_years, sold_in}) => {
  const [selected_finish, setSelectedFinish] = useState(finishes[0]);

  useEffect(() => {
   // TODO GRM - update querystring value to track selected
  }, []);

  const renderSelectedYears = (available_years) => {
    const years_txt = getFinishYears(available_years, model_years);
    const notes = notesOnly(available_years);

    return <>{years_txt} {notes.length ? <span className="text-sm text-gray-400 ml-2">{notes.join(' ')}</span> : null }</>;
  };

  const renderSelectedRegions = (available_regions) => {
    let regions_txt = '';
    const regions = withoutNotes(available_regions);
    const notes = notesOnly(available_regions);
    const len = regions.length;
    
    regions.forEach((x, indx) => {
        if (x === 'ALL') {
            regions_txt += withoutNotes(sold_in).join(', ');
        } else {
            regions_txt += x;
        }

        if (indx !== len - 1) {
            regions_txt += ', ';
        }
    });

    return <>{regions_txt} {notes.length ? <span className="text-sm text-gray-400 ml-2">{notes.join(' ')}</span> : null }</>;
  };

  return <section className="flex flex-col ">

    <div id="finish-preview" className="w-full relative" aria-labelledby={finishes.map((x) => { return `finish-${x.abbrev}` }).join(' ')}>
      <div className="w-full bg-gray-800">
        {selected_finish && selected_finish.preview ?
          <GatsbyImage image={getImage(selected_finish.preview)} alt={`Preview of ${selected_finish.name} finish`} className="block pixelate" />
          :
          <div className="aspect-w-16 aspect-h-9"></div>
        }
      </div>

      {selected_finish.preview_credit || selected_finish.preview_note ?  
        <span className="text-xs italic block p-5 lg:text-right lg:px-2 lg:py-0.5 text-gray-450 bg-black lg:absolute bottom-0 right-0 bg-opacity-60">
          {selected_finish.preview_note ? 
            <span className="text-white">{selected_finish.preview_note} {selected_finish.preview_credit ? <br /> : null}</span>
            : null
          }
          {selected_finish.preview_credit ? 
            <span>Photo credit: {selected_finish.preview_credit}</span>
            : null
          }
          </span>
        : null
      }
        
    </div>
    <div className="w-full p-10">
        <ul className="finish-btn-list">
            {finishes.map((x) => <li key={x.abbrev}>
              <button id={`finish-${x.abbrev}`} aria-expanded={x === selected_finish} aria-controls="finish-preview finish-content" className="cursor-pointer text-center font-mono" onClick={(e) => {
                e.preventDefault();
                setSelectedFinish(x);
            }}>
              <div className={`thumb-wrap ${x === selected_finish ? 'selected-finish' : ''}`}>
                {x.thumbnail ?
                  <GatsbyImage image={getImage(x.thumbnail)} alt={`${x.name} finish swatch`} className="block pixelate" title={x.name} />
                  : <span className="absolute left-0 top-0 w-full h-full text-xs flex justify-center items-center">{x.name}</span>
                }
              </div>
              {x.abbrev}
            </button></li>)}
        </ul>

        {selected_finish && <div id="finish-content" className="mt-8" aria-labelledby={finishes.map((x) => { return `finish-${x.abbrev}` }).join(' ')}>
            <div className="mb-1 "><span className="spec-label">selected finish</span> <span className="text-xl font-bold">{selected_finish.name} ({selected_finish.abbrev})</span></div>
            <div className="mb-1 "><span className="spec-label">available years</span> <span className="spec">{renderSelectedYears(selected_finish.available_years)}</span></div>
            <div className="mb-1 "><span className="spec-label">available regions</span> <span className="spec">{renderSelectedRegions(selected_finish.available_regions)}</span></div>
            {selected_finish.details && <div className="text-sm mt-2">{selected_finish.details}</div>}
        </div>}
    </div>

    
  </section>;
}


FinishPicker.propTypes = {
  finishes: PropTypes.array,
}

FinishPicker.defaultProps = {
    finishes: [],
}

export default FinishPicker
