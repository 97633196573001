import { GatsbyImage, getImage } from "gatsby-plugin-image"

import React from "react"

const ModelHero = ({model, img, alt}) => (
<div className="max-h-90vh  overflow-hidden bg-black relative max-w-1920 mx-auto lg:flex lg:items-center">
    <div className="w-full">
        <GatsbyImage image={getImage(img)} alt={alt} className="h-full block" />
    </div>

    <h2 className="text-shadow-subtle page-heading pl-5 absolute bottom-0 left-0">{model}</h2>
</div>
)

export default ModelHero
