import { Footnotes, FootnotesProvider, FootnoteRef as Ref } from "../footnotes"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"

import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import ComingSoon from "../components/ComingSoon"
import FinishPicker from "../components/FinishPicker"
import FootnotesBackLink from "../components/FootnotesBackLink"
import LastUpdated from "../components/LastUpdated"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import ListingHelper from "../components/ListingHelper"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from 'gatsby-plugin-mdx'
import ModelHero from "../components/ModelHero"
import ModelHighlights from "../components/ModelHighlights"
import React from "react"
import Seo from "../components/seo"
import Slider from "../components/Slider"
import SpecsTable from "../components/SpecsTable"
import { graphql } from "gatsby"

const shortcodes = { Link, FinishPicker, Ref, ListingHelper }

export const pageQuery = graphql`
query ModelPage($model_name: String) {
  mdx(frontmatter: {model: {eq: $model_name}}) {
    frontmatter {
      model
      title
      desc
      last_modified
      collections
      slug
      hero {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, quality: 90)
        }
      }
      hero_alt
    }
    body
  }
  modelsYaml(model: {eq: $model_name}) {
    brand
    model
    model_years
    made_in
    sold_in
    finishes {
      name
      abbrev
      preview_credit
      preview_note
      preview {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, aspectRatio: 1.78, quality: 75)
        }
      }
      thumbnail {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, height: 60, width: 60, quality: 90)
        }
      }
      details
      available_regions
      available_years
    }
    specs {
      note {
        body
      }
      body {
        type
        material
        weight
        total_weight
        dimensions {
          length
          thickness
          tummy_cut
        }
        shielding
        markings
      }
      electronics {
        controls
        
        pickups {
          pickup_config
          neck {
            model
            _mdx_model {
              body
            }
            type
            dcr
            polarity
          }
          middle {
            model
            _mdx_model {
              body
            }
            type
            dcr
            polarity
          }
          bridge {
            model
            _mdx_model {
              body
            }
            type
            dcr
            polarity
          }
        }
      }
      hardware {
        finish
        
        bridge
        
        
        pickguard {
          material
          finish
          weight
          shielding
          screws
        }
        knobs
        switch_tip
        rear_cover {
          material
          finish
          screws
        }
        jack_plate {
          weight
          screws
        }
        
      }
      neck {
        shape
        material
        weight
        joint
        
        
        dimensions {
          scale_length
          fretboard_radius

          thickness {
            _1st_fret
            _12th_fret
          }
          width {
            nut
            _1st_fret
            last_fret
          }
          
          nut_string_spread
          bridge_string_spread
          string_space
        }
        
        fretboard {
          material
          markers
          frets
          extension
          fret_wire {
            brand
            model
            material
            details
          }
        }
        side_markers
        nut
        strings
        factory_tuning
        
        markings
        
        headstock {
          angle
          tuners {
            brand
            type
            finish
            ratio
            button_style
            markings
          }
          
        }
        
        
        
        
        truss_rod {
          tool
          end
          cover
        }
        
      }
    }
    highlights {
      alt
      desc {
        body
      }
      img {
        id
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, aspectRatio: 1.33, layout: FULL_WIDTH, quality: 75)
        }
      }
      title
    }
    gallery {
      alt
      credit
      note
      img {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, aspectRatio: 1.78, layout: FULL_WIDTH, quality: 75)
        }
      }
    }
  }
}
`


const ModelPageLayout = (props) => {
  // console.warn('props', props)
  // console.warn('props.pageContext', props.pageContext)
  // console.warn('props.data', props.data)

    const frontmatter = props.data.mdx.frontmatter;
    const model_data = props.data.modelsYaml;
    const gallery_imgs = model_data.gallery;
    const {
      breadcrumb: { crumbs },
    } = props.pageContext;

    const CustomTab = ({children}) => (
      <Tab>
        <h1>{children}</h1>
      </Tab>
    );
    CustomTab.tabsRole = 'Tab'; // Required field to use custom Tab

    const tabbed_content = <Tabs key={model_data.model} className="model-tabs" forceRenderTabPanel={false} defaultIndex={0} 
    selectedTabClassName="model-tabs-tab--selected" 
    disabledTabClassName="model-tabs-tab--disabled"
    selectedTabPanelClassName="model-tabs-panel--selected">
        <TabList className="model-tabs-tablist">
            <Tab className="model-tabs-tab" tabIndex="0">Highlights</Tab>
            <Tab className="model-tabs-tab" tabIndex="0">Specs</Tab>
            <Tab className="model-tabs-tab" tabIndex="0">Diagrams</Tab>
            <Tab className="model-tabs-tab" tabIndex="0">Parts</Tab>
        </TabList>

        <TabPanel className="model-tabs-panel"><ModelHighlights highlights={model_data.highlights} /></TabPanel>
        <TabPanel className="model-tabs-panel"><SpecsTable specs={model_data.specs} /></TabPanel>
        <TabPanel className="model-tabs-panel">
            <div className="min-h-30vh flex flex-col justify-center items-center">
              <div className="text-xl text-center"><h2 className="font-bold">Diagrams</h2><ComingSoon /></div>
            </div>
        </TabPanel>
        <TabPanel className="model-tabs-panel">
            <div className="min-h-30vh flex flex-col justify-center items-center">
              <div className="text-xl text-center"><h2 className="font-bold">Parts</h2><ComingSoon /></div>
            </div>
        </TabPanel>
    </Tabs>;

    
    return <Layout headerOverlap>
        <FootnotesProvider>

          <Seo title={frontmatter.title} description={frontmatter.desc} />
          <div className="model-page bg-dark-night">

              <ModelHero model={model_data.model} img={frontmatter.hero} alt={frontmatter.hero_alt} />

              <div className="max-w-1920 mx-auto min-h-50vh">
                  <Breadcrumb crumbs={crumbs} crumbLabel={model_data.model} crumbSeparator="»" />

                  <div className="flex flex-col md:flex-row">
                    <div className="overview px-5 mb-5 w-full md:mb-0 md:w-1/2 lg:w-5/12">
                      <MDXProvider components={shortcodes}>
                          <MDXRenderer tabbed_content={tabbed_content} model_data={model_data}>{props.data.mdx.body}</MDXRenderer>
                      </MDXProvider>
                    </div>

                    <div className="md:w-1/2 lg:w-7/12">
                      <FinishPicker finishes={model_data.finishes} model_years={model_data.model_years} sold_in={model_data.sold_in} />
                    </div>
                  </div>

                  {tabbed_content}

                  {gallery_imgs && gallery_imgs.length ? 
                    <Slider className="w-full relative" classNameSlider="max-h-90vh" forcePause={true}>
                      {gallery_imgs.map((x, indx) => (
                        <div key={indx} >
                          <GatsbyImage image={getImage(x.img)} alt={x.alt} className="block pixelate" />
                          {x.credit || x.note ?  
                            <span className="text-xs italic block p-5 lg:text-right lg:px-2 lg:py-0.5 text-gray-450 bg-black lg:absolute bottom-0 right-0 bg-opacity-60 lg:max-w-1/2">
                              {x.note ? 
                                <span className="text-white">{x.note === 'USE_ALT' ? x.alt : x.note} {x.credit ? <br /> : null}</span>
                                : null
                              }
                              {x.credit ? 
                                <span>Photo credit: {x.credit}</span>
                                : null
                              }
                              </span>
                            : null
                          }
                        </div>
                      ))}
                    </Slider>
                    : null
                  }

                  <Footnotes BackLink={FootnotesBackLink} />
                  {frontmatter.last_modified && <LastUpdated date={frontmatter.last_modified} />}
              </div>
          </div>

          
        </FootnotesProvider>
    </Layout>
};



export default ModelPageLayout