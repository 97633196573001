import Icons from "./Icons"
import { Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from 'gatsby-plugin-mdx'
import PhotoCredit from "./PhotoCredit"
import React from "react"
import { FootnoteRef as Ref } from "../footnotes"
import SpecAlt from "./SpecAlt"
import SpecSep from "./SpecSep"

const shortcodes = { Link, Ref, Icons, SpecAlt, SpecSep, PhotoCredit };

const FieldWithFootnote = ({value}) => {
    const val = value?.body || '';
        
    return <MDXProvider components={shortcodes}><MDXRenderer>{val}</MDXRenderer></MDXProvider>;
}

export default FieldWithFootnote
