import React, { useState } from "react"
import {getFinishYears, getModelYears} from "../utils/utils"

import renderTemplate from "react-text-templates"

const template = `
--------------

Brand: {BRAND}
Model: Talman {MODEL}
Finish: {FINISH}
Model Year(s): {YEARS}
Made in: {MADEIN}

Typical weight: {WEIGHT}
Scale length: {SCALE}


Body
-------
Type: {BODY_TYPE}
Material: {BODY_MATERIAL}


Neck
-------
Shape: {NECK_SHAPE}
Material: {NECK_MATERIAL}

Thickness
@1st fret: {THICK_1}
@12th fret: {THICK_12}

Width
@nut: {WIDTH_NUT}
@1st fret: {WIDTH_1ST}
@last fret: {WIDTH_LAST}


Fretboard
-------
Radius: {RADIUS}
Material: {FRETBOARD_MATERIAL}
Frets: {FRET_NUM}
Fret wire: {FRET_WIRE}


Electronics
-------
Controls: {CONTROLS}
Pickup config: {PICKUP_CONFIG}
Neck pickup: {NECK_PICKUP}
Middle pickup: {MID_PICKUP}
Bridge pickup: {BRIDGE_PICKUP}


Hardware
-------
Bridge: {BRIDGE}
Hardware finish: {HARDWARE_FINISH}


--------------

Find more in-depth details at Tal's Guide
{URL}
`;

const ListingHelper = ({model_data}) => {
    const [is_open, setIsOpen] = useState(false);
    const [copyStatus, setCopyStatus] = useState('');
    const [details_txt, setDetailsTxt] = useState('');



    const help_txt = is_open ? '' : <><span role="img" aria-label="eyes" className="mx-2 text-lg">👀</span> Looking to copy details to list your guitar for sale?</>;
    const btn_txt = is_open ? 'Close' : 'Yes, help me';

    const resetCopyStatus = () => {
        setCopyStatus('');
    };

    const copyToClipBoard = async () => {
        resetCopyStatus();

        try {
            await navigator.clipboard.writeText(details_txt);
            setCopyStatus('Copied!');
        } catch (err) {
            setCopyStatus(<span>Failed to copy. <span role="img" aria-label="crying face" className="text-lg">😢</span> You can try manually selecting the above details and copying.</span>);
        }
    };

    const handleSelectChange = (e) => {
        e.preventDefault();

        let finish_match, years, finish, years_arr, single_year_short, token_keys;

        resetCopyStatus();

        if (e.target.value) {

            finish_match = model_data.finishes.find((x) => x.abbrev === e.target.value);

            if (finish_match) {
                finish = `${finish_match.name} (${finish_match.abbrev})`;
                years = getFinishYears(finish_match.available_years, model_data.model_years);
            } else if (e.target.value === 'custom') {
                finish = 'Custom finish';
                years = getModelYears(model_data.model_years);
            } else {
                finish = 'unknown';
                years = getModelYears(model_data.model_years);
            }

            years_arr = years.split(',');

            let tokens = {
                BRAND: model_data.brand,
                MODEL: model_data.model,
                FINISH: finish,
                YEARS: years,
                MADEIN: model_data.made_in,
                WEIGHT: model_data?.specs?.body?.total_weight || ' ',
                SCALE: model_data?.specs?.neck?.dimensions?.scale_length || ' ',
                BODY_TYPE: model_data?.specs?.body?.type || ' ',
                BODY_MATERIAL: model_data?.specs?.body?.material || ' ',
                NECK_SHAPE: model_data?.specs?.neck?.shape || ' ',
                NECK_MATERIAL: model_data?.specs?.neck?.material || ' ',
                THICK_1: model_data?.specs?.neck?.dimensions?.thickness?._1st_fret || ' ',
                THICK_12: model_data?.specs?.neck?.dimensions?.thickness?._12th_fret || ' ',
                WIDTH_NUT: model_data?.specs?.neck?.dimensions?.width?.nut || ' ',
                WIDTH_1ST: model_data?.specs?.neck?.dimensions?.width?._1st_fret || ' ',
                WIDTH_LAST: model_data?.specs?.neck?.dimensions?.width?.last_fret || ' ',
                RADIUS: model_data?.specs?.neck?.dimensions?.fretboard_radius || ' ',
                FRETBOARD_MATERIAL: model_data?.specs?.neck?.fretboard?.material || ' ',
                FRET_NUM: model_data?.specs?.neck?.fretboard?.frets || ' ',
                FRET_WIRE: model_data?.specs?.neck?.fretboard?.fret_wire?.details || ' ',
                CONTROLS: model_data?.specs?.electronics?.controls || ' ',
                PICKUP_CONFIG: model_data?.specs?.electronics?.pickups?.pickup_config || ' ',
                NECK_PICKUP: model_data?.specs?.electronics?.pickups?.neck?.model || ' ',
                MID_PICKUP: model_data?.specs?.electronics?.pickups?.middle?.model || ' ',
                BRIDGE_PICKUP: model_data?.specs?.electronics?.pickups?.bridge?.model || ' ',
                BRIDGE: model_data?.specs?.hardware?.bridge || ' ',
                HARDWARE_FINISH: model_data?.specs?.hardware?.finish || ' ',
                URL: `talsguide.com${window.location.pathname}`
            };


            // Note, remove year specific info that doesn't match
            if (years_arr.length <= 1) {
                single_year_short = years_arr[0].slice(-2);

                // console.warn('single_year_short', single_year_short)
                token_keys = Object.keys(tokens);

                token_keys.forEach((key) => {
                    const token = tokens[key];

                    if (typeof token === 'string') {
                        let txt_arr = token.split('·');
                        let txt_arr_mod;

                        if (txt_arr.length > 1) {
                            txt_arr_mod = txt_arr.map((x) => {
                                if (x.includes(`['`)) {
                                    if (x.includes(`['${single_year_short}]`)) {
                                        return x.trim();
                                    } else {
                                        return null;
                                    }
                                } else {
                                    return x.trim();
                                }
                            }).filter((x) => { return x !== null; });

                            // console.warn('txt_arr_mod', txt_arr_mod)

                            // override text
                            tokens[key] = txt_arr_mod.join('  ·  ');
                        }
                    }                    
                });
            }

            setDetailsTxt(renderTemplate(template, tokens).replaceAll('&quot;', '"'));
        } else {
            setDetailsTxt('');
        }
    };

    
    

    return <div className="p-1 my-4 bg-gradient-to-tr from-blue-600 to-pink-600 rounded-md">
    <div className="p-2 rounded-md bg-opacity-90 bg-gray-800">
 
        <div className={`font-bold ${is_open ? 'flex justify-end pb-2 mx-4 border-b border-white border-opacity-40' : ''}` }>
            {help_txt} <button className="underline ml-4 whitespace-nowrap" aria-controls="listing-helper-ui" aria-expanded={is_open} onClick={(e) => {
                e.preventDefault();
                setIsOpen(!is_open);
                resetCopyStatus();
            }}>{btn_txt}</button>
        </div>

        {is_open && <div id="listing-helper-ui" className="p-4" role="region">
       
            <p className="border-b border-white border-opacity-40 pb-4 mb-4 text-center text-xl">This little tool will generate the essential, model specific details to help list your Talman for sale</p>
            <p className="text-sm">...and while you're here. I'm always interested in buying Talmans, please <a href="/contact">contact me</a>!</p>
            
            <label htmlFor="listing-helper-finish" className="block mt-8 text-sm font-bold text-gray-400">Your guitar's finish</label>
            <select id="listing-helper-finish" className="text-white p-3 border-2 border-black rounded-md bg-black bg-opacity-40 max-w-full" onChange={handleSelectChange}>
                <option value="">Select finish</option>
                <option disabled value="">---------</option>
                <option value="custom">Custom color</option>
                <option disabled value="">---------</option>
                {model_data.finishes.map((x) => {
                    return <option value={x.abbrev}>{x.abbrev} - {x.name}</option>
                })} 
            </select>
           
            
            <label htmlFor="listing-helper-details" className="block mt-4 text-sm font-bold text-gray-400">Details</label>
            <textarea readOnly={true} id="listing-helper-details" className="text-white w-full text-xs leading-3 p-3 border-2 border-black rounded-md h-40 bg-black bg-opacity-40" value={details_txt} />

            <div className="flex flex-row flex-wrap md:flex-nowrap items-center">
                <button className={`btn whitespace-nowrap mr-4 my-2 self-start ${!details_txt ? 'btn-disabled' : ''}`}  onClick={(e) => {
                    e.preventDefault();
                    if (details_txt) {
                        copyToClipBoard();
                    }
                }}>Copy details</button>
                <span>{copyStatus}</span>
            </div>
        </div>}  
    </div>
    </div>;
};

export default ListingHelper