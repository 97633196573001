import FieldWithFootnote from "./FieldWithFootnote"
import Icons from "./Icons"
import React from "react"
import { FootnoteRef as Ref } from "../footnotes"
import {renderSpecTxt} from "../utils/utils"

const renderChild = (obj) => {

    if (obj) {
        return Object.keys(obj).map((x) => {
            const item = obj[x];
            let mdx_key;

            // Dont output mdx by default, code below will search for those fields
            if (x.startsWith('_mdx')) { return null; }

            mdx_key = '_mdx_' + x;

            // Dont output if item has no value (prevents blank specs from showing)
            if (item) {

                // Nested object (not mdx)
                if (typeof item === 'object') {                
                    return <li key={x} className="mb-2">
                        <h4 className="font-bold mt-4 text-gray-450">{x.replace('_', ' ')}</h4>
                        <ul className="ml-8 mb-4">{renderChild(item)}</ul>
                    </li>;
                }
    
                // NOTE: mdx field takes precedence over normal fields, but note item cannot be blank!
                if (obj[mdx_key]) {
                    return <li key={x} className="mb-1 flex">
                        <span className="spec-label">{x.replaceAll('_', ' ')}</span> <span className="spec"><FieldWithFootnote value={obj[mdx_key]} /></span>
                    </li>;
                }
    
                
                return <li key={x} className="mb-1 flex">
                    <span className="spec-label">{x.replaceAll('_', ' ')}</span> <span className="spec">{renderSpecTxt(item)}</span>
                </li>;  
            }
           
            return null;
        }); 
    }
}
  


const SpecsTable = ({specs}) => {
    return <section className="flex flex-col px-0 sm:px-5">
        <div className="flex justify-center items-center text-center mb-8 bg-gray-800 py-2 px-3 rounded-md border border-green-400 text-xs sm:text-sm">
            <span className=""><Icons type="check_circle_solid" className="inline-block w-3 sm:w-4 mr-1 text-green-400  " /> All specs listed here were directly <Ref description={<span>I aim to have the most accurate details on every Talman. Read more <a href="/about#approach-to-talman-data-specs">about my approach to Talman data/specs</a>.</span>}>measured or verified by Tal's&nbsp;Guide</Ref></span>
        </div>

        {specs.note ?
            <div className="italic p-8 -mt-2 pt-0 text-center text-xs sm:text-sm"><FieldWithFootnote value={specs.note} /></div>
            : null
        }

        <div className="flex flex-wrap lg:flex-nowrap">
            <h2 className="font-bold w-full lg:w-auto mb-4">Technical Specifications</h2>

            <div className="w-full md:w-1/2 md:pr-8">
                <h3 className="font-bold font-serif text-3xl text-purple-400 border-b border-gray-475 mb-4">Body</h3>
                <ul>{renderChild(specs.body)}</ul>

                <h3 className="font-bold font-serif text-3xl text-purple-400 border-b border-gray-475 mb-4 mt-8">Neck</h3>
                <ul>{renderChild(specs.neck)}</ul>
            </div>

            <div className="w-full md:w-1/2">
                <h3 className="font-bold font-serif text-3xl text-purple-400 border-b border-gray-475 mb-4 mt-8 md:mt-0">Electronics</h3>
                <ul>{renderChild(specs.electronics)}</ul>

                <h3 className="font-bold font-serif text-3xl text-purple-400 border-b border-gray-475 mb-4 mt-8">Hardware</h3>
                <ul>{renderChild(specs.hardware)}</ul>
            </div>
        </div>
    </section>;
}


export default SpecsTable
