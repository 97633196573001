import React from "react"

const ComingSoon = () => {
    return <section>
        <h3>Coming soon... <span role="img" aria-label="construction sign">🚧</span></h3>
        <p>Sorry, I'm still working on this section.</p>
    </section>;
}


export default ComingSoon