import { GatsbyImage, getImage } from "gatsby-plugin-image"

import FieldWithFootnote from "../components/FieldWithFootnote"
import React from "react"

const ModelHighlights = ({highlights}) => {
    return <section className="flex flex-wrap lg:flex-nowrap  px-0 sm:px-5 ">
        
        <div className="w-full grid gap-12 md:gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">  
            {highlights.map((highlight, indx) => {
                return <div key={`highlight${indx}`} className="">
                    <GatsbyImage image={getImage(highlight.img)} alt={highlight.alt} className="block pixelate" />
                    <h3 className="font-serif font-bold text-lg md:text-xl text-center text-purple-400 pt-4 pb-6">{highlight.title}</h3>
                    <div className="text-sm md:text-base"><FieldWithFootnote value={highlight?.desc} /></div>
                </div>;
            })}
        </div>
    </section>;
}

export default ModelHighlights
